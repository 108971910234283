/* LoginSignUpForm.js */
.form-container {
  margin-top: 2vh;
}

.auth-form-label {
  color: #212529;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 14px;
}

.form-header {
  text-align: center;
  margin-bottom: 20px;
}

.auth-input {
  background-color: black;
  font-size: 14px;
  border: none;
  border-radius: 0;
  border-bottom: 2px solid #212529;
}

.auth-input:focus {
  background-color: black;
  box-shadow: none;
  border-color: #969696;
  color: #969696;
}

.auth-link {
  color: #0098d4;
}

.auth-link:hover,
.auth-link:active {
  color: #006c96;
}

.form {
  width: 80%;
  margin: auto;
}

.divider {
  margin-top: 30px;
  margin-bottom: 30px;
  max-width: 500px;
  background-color: #22252a;
}

.external-auth-button {
  background-color: seagreen !important;
  border: seagreen !important;
}

.icon-container {
  margin-right: 8px;
}

@media only screen and (min-width: 768px) {
  .form {
    width: 60%;
  }
}

@media only screen and (min-width: 576px) {
  .auth-form-label {
    text-align: right;
  }
}

button.toggler {
  border: none !important;
}
