.form-label {
  color: black;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 14px;
}

.form-input {
  border: none;
  border-radius: 0;
  border-bottom: 1px solid black;
  font-size: 14px;
  padding-left: 0;
}

.form-input:focus {
  box-shadow: none;
  border-bottom: 1px solid black;
}

.form-title {
  background-color: black;
  color: white;
  text-align: center;
  padding: 15px 0px;
}

.watchdog-form-container {
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: white;
}

.form-button {
  width: 100%;
  background-color: black;
  color: white;
}

.remove-outline:focus {
  outline: none;
}

.watchdog-link {
  font-size: calc(12px + 1vw);
  color: #0098d4;
  font-weight: 600;
  border-bottom: 4px solid #0098d4;
  text-decoration: none !important;
}

.watchdog-link:hover,
.watchdog-link:active {
  color: #006c96;
  border-bottom: 4px solid #006c96;
}

.usage-jumbotron {
  background-color: #1d1d1d;
  border-radius: 0;
  padding: 6vw;
  margin-bottom: 0;
}
