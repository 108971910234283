/* Plan.js */
.plan-card {
  height: 400px;
  margin: 15px;
  border-radius: 0;
}

.plan-card .card-header {
  color: white;
  background-color: #1d1d1d;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 0;
}

.plan-title {
  margin: 10px 0;
}

.circle-text-big {
  margin-right: 2px;
  font-size: 35px;
  line-height: 30px;
}

.circle-text-small {
  font-size: 14px;
}

.dollar-sign {
  top: -3px;
}

.plus-sign {
  font-size: 60%;
  top: -5px;
}

.plan-card .card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  margin-top: 50px;
  border-top: none;
}

.plan-info {
  text-align: left;
  display: flex;
  flex-direction: column;
}

.circle-icon {
  margin-right: 2px;
  margin-bottom: 2px;
}

.current-plan-text {
  width: 100%;
  background-color: #b4eeff;
}

.form-check .user-card {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.user-card input {
  position: inherit;
  cursor: pointer;
}

.circle {
  width: 110px;
  height: 110px;
  /* background: #578f92; */
  background-color: #0098d4;
  border: 2px solid white;
  border-radius: 50%;
  margin-bottom: -68px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: black;
}

/* PaymentForm.js */
.payment-form {
  text-align: center;
}

.submit-button {
  display: block;
  margin: auto;
}

/* CardSection.js */
.card-label {
  width: 100%;
}

.submit-button {
  background-color: #012749;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 20px;
}

.info-container {
  margin-left: 10%;
}

/* PaymentModal */
.MuiIconButton-root:focus {
  outline: none;
}
