.vertical-center {
  vertical-align: middle !important;
}

.header-padding {
  padding: 15px !important;
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}

.td-padding {
  padding-left: 0.8rem !important;
  padding-right: 0.8rem !important;
}

.table-main {
  margin-top: 35px;
  border-spacing: 0;
  border-collapse: separate;
  font-size: calc(13px + 0.2vw);
  background-color: white;
}

.switch-tr-head {
  background-color: #1d1d1d;
  color: white;
  font-size: calc(15px + 0.2vw);
}

.switch-tr-h {
  margin-bottom: 0;
}

.h-border-bottom {
  border-bottom: 1px solid #d8d8d8 !important;
}

@media (max-width: 768px) {
  .table-sm td,
  .table-sm th {
    padding: 1vw !important;
  }
}
