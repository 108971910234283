/* .col- (extra small devices - screen width less than 576px)
.col-sm- (small devices - screen width equal to or greater than 576px)
.col-md- (medium devices - screen width equal to or greater than 768px)
.col-lg- (large devices - screen width equal to or greater than 992px)
.col-xl- (xlarge devices - screen width equal to or greater than 1200px) */

/* Common */
.application {
  font-family: "Poppins", sans-serif;
  min-height: 100vh;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: "column";
}

.center {
  text-align: center;
}

.turquoise-text {
  color: #0098d4;
}

.link-button:hover,
.link-button:active,
.link-button:focus,
.link-button:visited {
  background-color: transparent !important;
  color: #007bff !important;
  border: none !important;
  box-shadow: none !important;
}

.link-button:hover {
  text-decoration: none;
}

.link-button {
  background-color: transparent;
  color: #007bff;
  border: none;
}

/* App.js */
.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(16px + 7vw);
}

/* NavBar.js */
.bold-logo-text {
  font-weight: 700;
}

.light-logo-text {
  font-weight: 200;
}

.navbar-logo {
  cursor: pointer;
}

.logo-font-size {
  font-size: calc(20px + 1vw);
  line-height: calc(14px + 1vw);
}

.white-text {
  color: white !important;
}

.black-text {
  color: black !important;
}

.nav-logo-container:hover {
  color: lightblue !important;
}

.gray-text {
  color: #969494 !important;
}

.link-container a {
  color: inherit;
}

.profile-icon {
  margin-right: 5px;
}

.shadow {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
}

.navStyle {
  background-color: #1d1d1d !important;
  color: white;
  padding: 15px;
}

.nav-link-button {
  background-color: transparent;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  border-radius: 0;
  border-color: #2d2d2d !important;
}

.nav-link-button:hover {
  background-color: transparent !important;
  border: none;
  /* padding: 0; */
  /* border-bottom: 1px solid #2d2d2d; */
  color: white !important;
}

.nav-link-button:focus {
  background-color: transparent;
  box-shadow: none;
}

.nav-link-button:active {
  background-color: transparent !important;
}

.nav-link-button:active:focus {
  box-shadow: none !important;
  background-color: transparent !important;
}

.nav-link:focus {
  outline: none;
}

.nav-thing {
  display: flex;
  margin-right: 25px;
}

.nav-thing-text {
  margin: 0 10px;
}

@media (max-width: 768px) {
  .navbar-logo {
    position: absolute;
    top: 21px;
    left: 50%;
    right: 50%;
  }
  .navStyle {
    padding: 15px 0 15px 0;
  }
  .link-container a {
    padding: 10px 0;
    border-bottom: 1px solid #2d2d2d;
    width: 100%;
  }

  .link-container button {
    padding: 10px 0;
    border-bottom: 1px solid #2d2d2d;
    width: 100%;
  }

  .nav-link-button:hover {
    border-bottom: 1px solid #2d2d2d;
  }

  .nav-link-button-text {
    margin-left: 25px;
  }

  .nav-thing-text {
    margin-left: 25px;
  }
}

@media (min-width: 768px) {
  .underline-animation {
    position: relative;
  }

  .underline-animation:before {
    content: "";
    position: absolute;
    width: 0;
    height: 4px;
    bottom: 0;
    left: 0;
    background-color: white;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .underline-animation:hover:before {
    visibility: visible;
    width: 100%;
  }

  .active-nav-link .nav-thing-text:after {
    content: "";
    position: absolute;
    z-index: 0;
    right: 0;
    width: 0;
    bottom: 0;
    background: white;
    height: 4px;
    left: 0;
    right: auto;
    width: 100%;
  }
}

.active-nav-link .nav-thing-text {
  color: white;
}

@media (max-width: 768px) {
  .active-nav-link .nav-thing-text {
    color: #0098d4;
  }
}

/* Spinner */
.spinner-container {
  height: 100vh;
  width: 100vw;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  top: 0;
  z-index: 15000;
}

.spinner {
  width: 70px;
  height: 70px;
}

.profile-head-text {
  font-size: calc(1.2vw + 0.8rem);
}

.profile-text,
.profile-text h6 {
  font-size: calc(0.4vw + 0.7rem);
}

.profile-row {
  line-height: 33px;
}
